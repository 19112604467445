body {
    margin: 0;
    font-family: -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
    font-kerning: normal;
    font-feature-settings: "kern", "liga", "clig", "calt";
}

p {
    line-height: 1.625;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 0.8125rem;
}

.red {
    color: red;
}

.home {
    margin-top: 1rem;
}

table {
    width: 100%;
    border-spacing: 5px;
    border-collapse: collapse;
}

th,
td {
    padding: 5px;
    text-align: center;
    border-bottom: 1px solid #aaa;
}

li {
    line-height: 1.625;
    margin-bottom: calc(0.8125rem / 2);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-add-to-calendar {
    height: 100%;
}

.react-add-to-calendar__dropdown {
    background: white;
    position: absolute;
    margin-top: -60px;
    box-shadow: 0 0 0 0.5px rgba(50, 50, 93, .17), 0 2px 5px 0 rgba(50, 50, 93, .1), 0 1px 1.5px 0 rgba(0, 0, 0, .07), 0 1px 2px 0 rgba(0, 0, 0, .08), 0 0 0 0 transparent;
}

.react-add-to-calendar__dropdown ul {
    list-style: none;
    margin: 0;
    padding: 5px 15px;
}

.react-add-to-calendar__dropdown li {
    padding: 5px 0;
}

.react-add-to-calendar__dropdown a {
    text-decoration: none;
    color: black;
    font-size: 14px;
}

.react-add-to-calendar__button {
    padding-top: 8px;
    height: 100%;
    display: block;
    cursor: pointer;
}