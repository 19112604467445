.App {
    max-width: 1280px;
    margin: 0 auto;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.title-background {
    background-image: url(./imgs/header-background.jpg);
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.left-container {
    margin-left: 2%;
    display: inline-block;
    width: 20%;
    vertical-align: top;
}

.main-container {
    width: 52%;
    padding: 0 2%;
    display: inline-block;
    vertical-align: top;
}

.right-container {
    display: inline-block;
    width: 20%;
    margin-top: 10px;
    vertical-align: top;
}

.footer {
    display: block;
    height: 1rem;
    width:100%;
    margin-top: 20px;
    background-color: #aaa;
}