.header-wrapper {
    background-color: #d3d3d3;
    text-align: center;
    h3 {
        padding: 0.5em;
        margin: 0;
        font-size: 21px;
    }
}

.organizer-header {
    text-align: center;
}
.ieee, .signal {
    width: 100%;
    margin-top:15px;
}